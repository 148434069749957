import React from 'react';
import { MessageKey } from '../locales/en';
import { Trans, useTranslation } from 'react-i18next';

export const useMessages = () => {
  const { t: getMessage } = useTranslation();

  return (message: MessageKey) => getMessage(message);
};

interface FormattedStringProps {
  message: MessageKey;
  components?: Record<string, React.ReactElement>;
  values?: Record<string, any>;
}

export const FormattedString: React.FC<FormattedStringProps> = ({
  message,
  components,
  values,
}) => {
  const { t: getMessage } = useTranslation();

  return (
    <Trans i18nKey={message} components={components} values={values}>
      {getMessage(message)}
    </Trans>
  );
};
